<template>
  <div class="w-full relative p-4 bg-white text-secondary block rounded shadow">
    <div class="pb-3 border-grey-dark md:border-b-1 md:flex md:justify-between">
      <div class="mr-4">
        <p
          v-if="data.title" 
          data-cms-text="basicCtaGadget.title"
          data-cy="basic-cta-title"
          class="mt-2 md:mt-0 mb-2 leading-tight text-2xl lg:text-2xl font-semibold"          
        >
          {{ data.title }}          
        </p>
        <p v-if="data.subTitle" 
          data-cms-text="basicCtaGadget.subTitle"
          data-cy="basic-cta-subtitle"
          class="w-full mb-3 leading-tight text-2xl"
        >
          {{ data.subTitle }}
        </p>
        <p v-if="!data.subTitle || data.subTitle === '' " 
            data-cms-text="basicCtaGadget.subTitle"
            data-cy="basic-cta-subtitle"
            aria-hidden="true"
          >
            {{ data.subTitle }}
        </p>

        <p v-if="data.substantiationText" 
            data-cms-text="basicCtaGadget.substantiationText"
            class="text-sm md:text-xs"
          >
            {{ data.substantiationText }}
        </p>
        <p v-if="!data.substantiationText || data.substantiationText === '' " 
            data-cms-text="basicCtaGadget.substantiationText"
            aria-hidden="true"
          >
            {{ data.substantiationText }}
        </p>        
      </div>

      <div>
        <a
          v-if="data.primaryCta"
          v-on:click="$generalClick({'label': data.primaryCta.gaEventLabel}, $event, data.primaryCta.url)"
          :href="data.primaryCta.url"
          rel="nofollow"
          class="w-full md:w-auto mt-4 md:m-0 inline-block p-3 whitespace-no-wrap px-6 rounded no-underline bg-text-link hover:bg-text-link-dark text-white  text-center font-medium">
          {{ data.primaryCta.text }}
        </a>
      </div>
    </div>

      <div v-if="data.poweredBy" class="flex justify-center md:justify-start items-center md:mt-2 md:-mb-2">
        <p class="text-xs mr-1" 
            data-cms-text="basicCtaGadget.poweredBy.text">
            {{ data.poweredBy.text }}
        </p>
        <Icon :name="data.poweredBy.icon" width="130" height="30" :ariaHidden="false" viewBox="0 0 300 30"/>
      </div>

  </div>
</template>

<script>
import components from "@/components";

export default {
  name: "BasicCtaGadget",
  components: components,
  props: { data: Object }
 };
</script>